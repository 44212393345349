<template>
    <div class="container">
        <div class="button" @click="choice_send_document">Envoyer un document</div>
        <file-component v-if="send"/>
    </div>
</template>
<script>
//import ScannerComponent from '@/components/SendDocuments/ScannerComponent.vue'
import FileComponent from '@/components/SendDocuments/FileComponent.vue'
//import ScanopenComponent from '@/components/SendDocuments/ScanopenComponent.vue'
export default {
    name: 'SendDocumentsComponent',
    components: {
        //ScannerComponent,
        FileComponent,
        //ScanopenComponent
    },
    data(){
        return{
            send: false,
            scan: false
        }
    },
    methods:{
        choice_send_document(){
            this.send = !this.send
            //this.scan = false
        },
        // choice_scan_document(){
        //     this.scan = true
        //     this.send = false
        // }
    }
}
</script>
<style lang="scss" scoped>

    .container{
        margin: 20px;
        font-size: 2rem;
        border:rgb(126, 192, 126) solid 3px;
        color: rgb(126, 192, 126);
        text-align: center;
        border-radius: 50px;
    }

    .button{
        margin: 20px;
        border-radius: 10px;
        color: rgb(126, 192, 126);
        cursor: pointer;
    }
    @media screen and (max-width: 700px){
        .container{
            margin: 0px;
            text-align: center;
            color: rgb(126, 192, 126);
        }
        .button{
            border-radius: 50px;
            color: rgb(126, 192, 126);
            cursor: pointer;
        }
    }
</style>