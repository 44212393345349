<template>
    <div class="send_document">
        <input class="form-control" type="file" id="fileInput" required>
        <div @click="submitFile"><i class="bi bi-arrow-right-square-fill"></i></div>
    </div>
</template>
<script>
export default {
    name: 'FileComponent',
    data(){
        return{
            image: null,
            base64File: ""
        }
    },
    methods: {
        submitFile(){
            const file = document.getElementById('fileInput').files[0]
            console.log(file)
            if(file != null){
                const name_file = file.name
                const extension = file.type
                console.log(name_file)
                console.log(extension)
                this.getBase64(file)
            } else {
                let alert = document.getElementById('fileInput')
                alert.style.border= 'red solid 4px'
                alert.style.color= 'red'

            }
        },

        getBase64(file){
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                this.base64File = reader.result.split(',')[1]
                const  body = {
                    'filename' : file.name,
                    'extension': file.extension,
                    'send_date' : Date.now(),
                    'data' : this.base64File,
                    'userId' : localStorage.getItem('userId')
                }

                try {
                    fetch(process.env.VUE_APP_DIST+'/event/api/document/'+localStorage.getItem('userId'), {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'api_key_auth': process.env.VUE_APP_API_KEY
                            },
                        body: JSON.stringify(body)
                    })
                    .then((response) => {
                        console.log(response)
                        this.$router.push('/calendar')
                    })
                } catch(error) {
                    console.log(error)
                }
            };

            reader.onerror = (error) => {
                console.log('Error: ', error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .send_document{
        display: flex;
        
        align-items: center;
        margin: 20px;
        padding: 10px;
    }
    #fileInput{
        color: green;
        border: green solid 3px;
    }
    .bi::before {
        font-size: 35px;
        margin: 10px;
        color: green;
    }
</style>