<template>
    <div class="container">
        <h1>Page introuvable!</h1>
    </div>
</template>
<script>
export default {
    name: 'pageNotFound'
}
</script>
<style lang="css" scoped>
    .container{
        align-content: center;
    }
</style>