<template>
  <nav>
    <div class="navigation" v-if="userId">
      <router-link id="calendar" to="/calendar"><i class="bi bi-calendar3" ></i></router-link>
      <router-link id="documents" to="/documents"><i class="bi bi-file-arrow-up" ></i></router-link>
      <router-link id="logout" to="/" @click="eraseLocalStorage"><i class="bi bi-door-closed" ></i></router-link>
    </div>
    <router-link id="home" to="/"><img alt="navette" src="@/assets/logo-pills-granpharma.jpg"></router-link>
  </nav>
  <router-view/>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import store from './store'
export default {
  name: 'App',
  computed:{
    ...mapState(['userId']),
    ...mapActions(['eraseUserId'])
  },
  beforeCreate() {
    if(localStorage.getItem('token') === null ){
      this.$router.push('/')
    }
  },
  methods: {
    eraseLocalStorage(){
      localStorage.removeItem('userId')
      localStorage.removeItem('token')
      //this.userId = null
      store.dispatch('eraseUserId')
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.navigation{
  display: flex;
}
nav a {
  font-weight: bold;

  color: #f3bf71;
  padding: 10px;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#home{
  font-size: 3rem;
  
}
#calendar{
  font-size: 3rem;
  
}
#documents{
  font-size: 3rem;
 
}
#logout{
  font-size: 3rem;
  
}

@media screen and (max-width: 700px){
  nav{
    display: flex;
    align-items: center;
    flex-direction: column;
    
  }
  .navigation{
    display: flex;
  }
  #home{
  font-size: 2rem;
  
  }
  #calendar{
    font-size: 2rem;
  
  }
  #documents{
    font-size: 2rem;
    
  }
  #logout{
    font-size: 2rem;
    
  }

}
</style>
