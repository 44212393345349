import { useFetchGlobal } from './fetchGlobal.js'

export async function useFetchEventAdd(data){ 
    const eventAdd = await useFetchGlobal( process.env.VUE_APP_DIST + '/event/api/new/' + localStorage.getItem('userId'), 'POST', data)
    return eventAdd
}

export async function useFetchEventsGet(){ 
    const eventGet = await useFetchGlobal(process.env.VUE_APP_DIST + '/event/api/show/' + localStorage.getItem('userId'), 'GET', null)
    return eventGet
}

export async function useFetchEventChange(eventId, data){ 
    const eventChange = await useFetchGlobal(process.env.VUE_APP_DIST + '/event/api/' + eventId + '/edit', 'PUT', data)
    return eventChange
}

export async function useFetchEventRemove(eventId){ 
    const eventRemove = await useFetchGlobal(process.env.VUE_APP_DIST + '/event/api/' + eventId + '/remove', 'DELETE', null)
    return eventRemove
}