<template>
  <div class="container">
      <FullCalendar 
      :options="calendarOptions" 
      />
  
        <event-manager
        :idEvent = "tabEvent.event_id"
        :title = 'tabEvent.title'
        :start = 'tabEvent.start'
        :end = 'tabEvent.end'
        :description = 'tabEvent.description'
        :validEventId = 'tabEvent.validEventId'
        />
    
 
  </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import EventManager from '../components/EventManagerComponent.vue'
import { useFetchEventsGet, useFetchEventAdd, useFetchEventChange } from '../composables/fetchEvents.js'
import { mapState } from 'vuex'

export default {
  name: "CalendarView",
  components: {
    FullCalendar,
    EventManager
  },
  data () {
    return {
      tabEvent: [],
      calendarOptions: {
        plugins: [bootstrap5Plugin, dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        themeSystem: 'bootstrap5',
        contentHeight: 600,
        initialView: 'dayGridMonth',
        locale: 'fr',
        timeZone: 'Europe/Paris',
        headerToolbar: {
          left: 'prev,next,today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
        },
        buttonText: {
            today: "aujourd'hui",
            month: "Mois",
            week: "Semaine",
            day: "Jour",
            list: "Liste",
          },
        weekNumbers: true,
        weekNumberFormat: { week: 'numeric'},
        dayMaxEvents: false,
        nowIndicator: true,
        editable: true,
        selectable: true,
        eventResizableFromStart: true,
        weekends: true,
        //affichage des evenements
        events: async() => {
          const events = await useFetchEventsGet()
          console.log(events)
          console.log(events.length)
          if(events != 'no events'){
            this.modalEvents = events
            return events
          } else {
            return this.tabEvent
          }
        },
        // creation d'un nouvel evenement et ajout dans la bdd par eventAdd
        select: (arg) => {
          console.log(arg.start + arg.end)
          const cal = arg.view.calendar
          console.log(cal)
          cal.unselect()
          cal.addEvent({
            id: arg.id,
            title: '---- Choisir ----',
            start: arg.start,
            end: arg.end,
            extendedProps: {
              description: 'RAS' // laisser rempli sinon, ça crée une erreur
            },
            allDay: false,
          })
          
        },
        // selection d'un evenement
        eventClick: (arg) => {
          console.log(arg.event)
          // let startHour = new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'Europe/Paris'}).format(arg.event.start.toISOString().slice(0, 19))
          // console.log(startHour)
          let tabEventClicked = {
            event_id : arg.event.id,
            title : arg.event.title,
            start : arg.event.start.toISOString().slice(0, 19), //conversion au format date-time pour input mais ne tient pas compte de l'heure hiver/ete
            // start : arg.event.start.toLocaleString('fr-FR').slice(0, 19), //conversion au format date-time pour input en tenant compte de l'heure d'hiver/ete
            // start: startHour,
            end : arg.event.end.toISOString().slice(0, 19), //conversion au format date-time pour input mais ne tient pas compte de l'heure hiver/ete
            // end : arg.event.end.toLocaleString('fr-FR').slice(0, 19), //conversion au format date-time pour input en tenant compte de l'heure d'hiver/ete
            description : arg.event.extendedProps.description,
            validEventId : arg.event.extendedProps.validEventId
          }
        
          this.tabEvent = tabEventClicked
          console.log(this.tabEvent)
          
          this.createModalBtn(this.tabEvent.event_id) //creation du bouton modal
            
        },
        // ajout d'évenement dans la bdd
        eventAdd: (arg) => {
          console.log(arg.event)
          let data = {
            "userId": this.$store.state.userId,
            "title" : arg.event.title,
            "start" : arg.event.start,
            "end" : arg.event.end,
            "description" : arg.event.extendedProps.description,
            "validEventId": false,
            "allday" : false
          }
          useFetchEventAdd(data)
        },
        // modification d'un évenement par glissement ou allongement
        eventChange: (arg) => {
          console.log(this.$store.state.userId, arg.event.id, arg.event.title, arg.event.start, arg.event.end, arg.event.extendedProps.description)
          let data = {
            "userId": this.$store.state.userId,
            "title" : arg.event.title,
            "start" : arg.event.start,
            "finish" : arg.event.end,
            "description" : arg.event.extendedProps.description,
            "allDay" : false,
            'backgroundColor': 'blue',
            'borderColor': 'blue',
            'textColor': 'white',
            'validEventId': arg.event.extendedProps.validEventId
          }
          useFetchEventChange(arg.event.id, data)
          this.$router.push('/')
          
        },
        // eventRemove: (arg) => {
        //   console.log(arg)
        //   useFetchEventRemove()
        // },

      }
    }
  },
  computed:{
      ...mapState(['userId']),
  },
  methods:{
    createModalBtn(idEvent){
      console.log('---------------- FONCTION CREATE BUTTON ---------------------')
      console.log('debut de creation du bouton')
      console.log('évenement: '+idEvent)
      let eventTitle = document.getElementsByClassName('fc-daygrid-event-harness')
      console.log("Nombre d'evenements dans le DOM du calendrier: " + eventTitle.length)
      for(let i=0; i < eventTitle.length; i++){
        eventTitle[i].setAttribute('data-bs-toggle', 'modal')
        eventTitle[i].setAttribute('data-bs-target', '#modal' + idEvent)
      }
      console.log('fin de création du bouton')
    },
  }
}
</script>
<style lang="css" >
  FullCalendar{
    max-width: 1100px;
    margin: 40px auto;
  }
  .fc .fc-media-screen{
    height: 600px;
  }
  @media (max-width: 767.98px) {
    .fc .fc-toolbar.fc-header-toolbar {
        display: block;
        text-align: center;
    }

    .fc-header-toolbar .fc-toolbar-chunk {
        display: block;
    }
}
</style>
