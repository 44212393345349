<template>
  <div class="home">   
    <div class="container">
      <div 
        class="eventsMonth"
        v-for="(event, id) in tabEvents"
        :key="id"
      >
          <div class="info_event">
            <p><strong>{{ event.title }}</strong></p> 
            <p>du <strong>{{ event.start }}</strong> au <strong>{{ event.end }}</strong> inclus</p> 
          </div>
          <div class="reason_event">
            <p>Détails: {{ event.description }}</p>
            <div 
            class="button_zone"
            :id="'event' + event.id"
            v-if="event.validEventId === false"
            >
              <button class="btn btn-primary btn-sm" data-bs-toggle="modal" :data-bs-target="'#modal' + event.id" >Gérer</button>
              <button class="btn btn-warning btn-sm" @click="send_document(event.id)">piece jointe</button>
              <button class="btn btn-success btn-sm" @click="event_validate(event.id, event.title, event.start, event.end, event.description)">Valider</button>
            </div>
            <div v-else>
              <p>Vous avez validé cet évenement pour la feuille navette</p>
            </div>
          </div>
          <file-component v-if="event_doc == event.id" />
         
          <event-manager 
            :idEvent = "event.id"
            :title = 'event.title'
            :start = 'event.start'
            :end = 'event.end'
            :description = 'event.description'
            :validEventId = "event.validEventId"
            />
          <hr>
      </div>
        </div>
      </div>
</template>
<script>
// @ is an alias to /src

import { useFetchEventChange, useFetchEventsGet } from '../composables/fetchEvents.js'
import EventManager from '../components/EventManagerComponent.vue'
import FileComponent from '../components/SendDocuments/FileComponent.vue'
import { mapState } from 'vuex'
export default {
  name: 'HomeView',
  components: {
    EventManager,
    FileComponent
  },
  data(){
    return{
      event_doc: '',
      tabEvents : [],
      tabMonths: [
        'Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
      ]
      // TODO : valid_event_id a integrer dans le fetch
    }
  },
  async beforeCreate(){
      const events = await useFetchEventsGet()
      //console.log(events)
      if(events != 'no events'){
        //tri du tableau par date start
        events.sort((a,b) => {
          var c = new Date(a.start)
          var d = new Date(b.start)
          return c - d
        })
        // events.forEach((event) => {
        //   event.start = new Date(event.start).toLocaleString()
        //   event.end = new Date(event.end).toLocaleString()
          
        // });
        this.tabEvents = events
        console.log(this.tabEvents)
      } else {
        console.log('no events')
      }
    
  },
  computed: {
    ...mapState(['userId'])
  },
  methods :{
    event_validate(eventId, eventTitle, eventStart, eventEnd, eventDescription){
      let data = {
        "userId": this.$store.state.userId,
        'title': eventTitle,
        'start': new Date(eventStart).toUTCString(),
        'finish': new Date(eventEnd).toUTCString(),
        'description': eventDescription,
        'allDay': false,
        'backgroundColor': 'blue',
        'borderColor': 'blue',
        'textColor': 'white',
        'validEventId' : true
      }
      console.log(data)
      useFetchEventChange(eventId, data)
      this.$router.push('calendar')
    },

    send_document(eventId){
      this.event_doc = eventId
    }
  }
}
</script>
<style lang="css" scoped>
.home{
  margin: 40px;
}
  .eventsMonth{
    display: flex;
    flex-direction: column;
  }
  .info_event{
    display: flex;
    justify-content: space-between;
  }
  .reason_event{
    display: flex;
    justify-content: space-between;
  }
  button{
   margin: 5px;
  }
  @media screen and (max-width: 700px){
    .info_event{
      flex-direction: column;
    }
    .reason_event{
      flex-direction: column;
    }
  }
</style>