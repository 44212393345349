<template>

  <div class="home">
    <div class="content">
      <div v-if="userId" class="hours">
        <home-component />
      </div>
      <div class="user" v-else>
        <user-component/>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import HomeComponent from '@/components/homeComponent.vue'
import UserComponent from '@/components/UserComponent.vue'
import { mapActions, mapState } from 'vuex'


export default {
  name: 'HomeView',
  components: {
    HomeComponent,
    UserComponent
  },
  setup () {
    let token = localStorage.getItem('token')
    //console.log(token)
    return { token }
  },
  computed:{
    ...mapState(['userId']),
    ...mapActions(['eraseUserId'])
  },
}
</script>
<style lang="scss" scoped>
  .home{
    display: flex;
    flex-direction: column;
  }

  .hours{
    margin-top: 30px;
  }
  .user{
    margin-left: 30%;
    margin-right: 30%;
  }

   @media screen and (max-width: 700px){
  .user{
    margin-top: 150px;
    margin-left: 10%;
    margin-right: 10%;
  }

   }
</style>
