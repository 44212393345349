export async function useFetchGlobal (froute, fmethod, fdata ) {
    try {
        if(fmethod === 'POST' || fmethod === 'PUT') {
            await fetch(froute, {
                method: fmethod, 
                headers: {
                    'Content-Type': 'application/json',
                    'api_key_auth': process.env.VUE_APP_API_KEY
                },
                body: JSON.stringify(fdata)
            })
        } else if (fmethod === 'GET' || fmethod === 'DELETE'){
            const response = await fetch(froute, {
                method: fmethod,
                headers: {
                    'Content-Type': 'application/json',
                    'api_key_auth': process.env.VUE_APP_API_KEY
                }
            })
            const  json =  response.json()
            return json
        }
    } catch (error) {
        console.log(error)
    }
}