import { createStore } from 'vuex'

export default createStore({
  state: {
    userId: localStorage.getItem('userId')? localStorage.getItem('userId'): ""
  },
  getters: {
  },
  mutations: {
    SET_USER_ID(state, userId){
      state.userId = userId
    },

    ERASE_USER_ID(state){
      state.userId = ""

    }
  },
  actions: {
    setUserId(context, userId){
      context.commit('SET_USER_ID', userId)
    },
    eraseUserId(context){
      context.commit('ERASE_USER_ID')
    }
  },
  modules: {
  }
})
