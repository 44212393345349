<template>
    <div>
        <div
        v-if="sendpwd === false"
        >
        <div class="alert alert-danger" v-if="message">{{ message }}</div>
        <form class="row g-3 needs-validation" novalidate>
            <input class="form-control" type="email" id="email" placeholder="email" v-model="user.email" autocomplete="email" required>
            <div class="invalid-feedback">
                Entrez votre email
            </div>
            <input class="form-control" type="password" id="pwd" placeholder="password" v-model="user.password" autocomplete="off" required>
            <div class="invalid-feedback">
                Entrez votre mot de passe
            </div>
            <div class="forgot_pwd" @click="pwdZone">Mot de passe oublié?</div>
            <button class="button" @click="login">Entrer</button>
        </form>
        </div>
        <div
        v-if="sendpwd === true"
        >
            <form class="row g-3">
                
                    <input class="form-control" type="email" placeholder="Votre email" v-model="user.email" required>
              
                    <div class="back_login" @click="pwdZone">Retour</div>
                    <button class="button" @click="forgotpwd">Envoyer</button>
                
            </form>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import store from '@/store'
export default {
    name: 'UserComponent',
    data(){
        return{
            message: '',
            sendpwd: false,
            user: {
                email :  '', 
                password : ''
            }
        }
    },
    mounted(){
        // Example starter JavaScript for disabling form submissions if there are invalid fields
        (function () {
        'use strict'

        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.querySelectorAll('.needs-validation')

        // Loop over them and prevent submission
        Array.prototype.slice.call(forms)
            .forEach(function (form) {
            form.addEventListener('click', function (event) {
                if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
            })
        })()
    },
    computed: {
        ...mapState(['userId']),
        ...mapActions(['setUserId'])
    },
    methods:{
        async login(e) {
            e.preventDefault();
            //console.log(JSON.stringify(this.user))
            if(this.user.email !== "" && this.user.password !== ""){
                try{
                    await fetch(process.env.VUE_APP_DIST+"/api/user/login", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'api_key_auth':  process.env.VUE_APP_API_KEY
    
                        },
                        body: JSON.stringify(this.user)
                    })
                    .then((response) => {
                        //console.log(response.json())
                        return response.json()
                    })
                    .then((json) => {
                        //console.log(json)
                        if(json.code === 401){
                            this.message = 'Votre identifiant ou mot de passe ne permet pas de vous connecter.'
                        }
                        localStorage.setItem('token', json.token)
                        localStorage.setItem('userId', json.data.id)
                        store.dispatch('setUserId', localStorage.getItem('userId'))
                        this.$router.push('/calendar')
                    })
                } catch (error) {
                    console.log(error)
                }
            }  
        },
        forgotpwd(){
         
            let emailUser = this.user.email
            fetch (process.env.VUE_APP_DIST+'/reset-password/api/forgot_pwd', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(emailUser)
            })
            .then((response)=>{
                console.log(response)
            }) 
        },
        pwdZone(){
            this.sendpwd = !this.sendpwd
        }
    }
}
</script>
<style lang="scss" scoped>
    form{
       font-size: 1.5rem;
    }
    .form-control{
        font-size: 1.5rem;
    }
    .button{
        color: white;
        padding: 5px;
        background-color: rgba(0, 128, 0, 0.486);
        border-radius: 10px;
        border: white;
    }
    .forgot_pwd, .back_login{
        cursor: pointer;
    }
</style>