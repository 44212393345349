import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CalendarComponent from '@/views/CalendarView.vue'
import Documents from '../views/SendDocuments.vue'
import pageNotFound from '../views/pageNotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarComponent
  },
  {
    path: '/documents',
    name: 'documents',
    component: Documents
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'pageNotFound',
    component: pageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
