<template>
    <div class="modal fade" :id="'modal' + idEvent" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ title }} {{idEvent}} {{description}}</h5>
                <div class="close" data-bs-dismiss="modal" aria-label="Close">
                    <i class="bi bi-x-lg"></i>
                </div>
            </div>
            <div class="modal-body">
                <form>
                    <div>
                        <label for="event_title">Evenement</label>
                        <select id="event_title" class="form-control" :v-model="title" required>
                            
                            <option :selected="title">{{ title }}</option>
                            <option v-if="title != 'Heures en plus'" value="Heures en plus">Heures en plus</option>
                            <option v-if="title != 'Heures en moins'" value="Heures en moins">Heures en moins</option>
                            <option v-if="title != 'Congés'" value="Congés">Congés</option>
                        </select>
                    </div>
                    <div class="date">
                        <div>
                        <label for="event_start">DJT</label><br>
                        <input id="event_start" type="datetime-local" class="form-control" :value="start" required>
                        </div>
                        <div>
                        <label for="event_end">PJT</label><br>
                        <input id="event_end" type="datetime-local" class="form-control" :value="end" required>
                        </div>
                    </div>
                    <div>
                        <label for="event_description">Motif:</label>
                        <textarea id="event_description" class="form-control" placeholder="Description" :value="description" required></textarea>
                        <div class="invalid-feedback">
                            Merci de saisir une description de l'évenement
                        </div>
                    </div>

                    <div>
                    </div>
                </form>
            </div>
           
            <div 
            class="modal-footer"
            >
                <div
                v-if="validEventId === false"
                >
                    <button type="button" class="btn btn-danger btn-sm" @click="deleteEvent" data-bs-dismiss="modal">supprimer l'évenement</button>
                    <button type="button" class="btn btn-primary btn-sm" @click="updateEvent" data-bs-dismiss="modal">Ajouter</button>
                </div>
                <div v-else>
                    Vous avez validé cet évenement pour la feuille navette
                </div>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useFetchEventChange, useFetchEventRemove } from '@/composables/fetchEvents'

export default {
    name: 'EventManagerComponent',
    props: [
        'idEvent',
        'title',
        'start',
        'end',
        'description',
        'validEventId'
    ],
    data(){
        return{
            selected_title: '',
            eventManager:{
            up_title: this.title,
            up_description: this.description,
            up_start: this.start,
            up_end: this.end
            }
        }
    },
//    beforeUpdate() {
//     let event = {
//             up_title: this.title,
//             up_description: this.description,
//             up_start: this.start,
//             up_end: this.end
//         }
   
//     this.eventManager = event
//     },
//     mounted(){
//         let event = {
//             up_title: this.title,
//             up_description: this.description,
//             up_start: this.start,
//             up_end: this.end
//         }
//    console.log(event)
//     this.eventManager = event
//     },
    methods:{
        updateEvent(){
            try{

                let updateTitle = document.getElementById('event_title').value 
                console.log(updateTitle)
                let updateStart = document.getElementById('event_start').value
                let updateEnd = document.getElementById('event_end').value
                let updateDescription = document.getElementById('event_description').value
                
                //console.log(this.idEvent, this.up_title, this.up_start, this.up_end, this.up_description)
                //console.log(new Date(updateStart).toUTCString())
                let data = {
                    "userId": this.$store.state.userId,
                    'title': updateTitle,
                    'start': new Date(updateStart).toUTCString(),
                    // 'start': this.eventManager.up_start,
                    'finish': new Date(updateEnd).toUTCString(),
                    // 'finish': this.eventManager.up_end,
                    'description': updateDescription,
                    // 'description': this.eventManager.up_description,
                    'allDay': false,
                    'backgroundColor': 'blue',
                    'borderColor': 'blue',
                    'textColor': 'white',
                    'validEventId': false
                }
                console.log(this.idEvent, data)
                useFetchEventChange(this.idEvent, data)
                console.log(this.$route.name)
                if(this.$route.name === 'calendar'){
                    this.$router.push('/')
                } else {
                    this.$router.push('/calendar')
                }
            } catch(error){
                console.log(error)
            }
        },

        deleteEvent(){
            useFetchEventRemove(this.idEvent)
            if(this.$route.name === 'calendar'){
                    this.$router.push('/')
                } else {
                    this.$router.push('/calendar')
                }
        },
    }

}
</script>